/*
What's Fresh - Frontend - Styles/Side Nav

TABLE OF CONTENTS:
1.0 - Side Nav Header
2.0 - Side Nav
*/

//1.0 - Side Nav Header
#side-nav-header {
	padding: 1em;
	svg {
		left: .25em;
		position: relative;
		.word-mark {
			@include opacity(1);
			.animate & {
				@include transition(opacity);
			}
			.mini-nav & {
				height: 0; 
				@include opacity(0);
			}
			@include bp(tablet) {  
				height: 0; 
				@include opacity(0);
			}
		}
	}
}

#side-nav-trigger {
	@include burger();
	display: block;
	height: 1.5em;
	padding: .75em 1em;
	span {
		left: 3em;
	}
	em {
		bottom: .125em;
		color: $grey;
		font-style: normal;
		font-size: em(14px);
		height: auto;
		left: 5em;
		position: relative;
		@include opacity(1);
		.animate & {
			@include transition(opacity);
		}
		.mini-nav & { 
			height: 0; 
			@include opacity(0);
		}
		@include bp(tablet) {  
			height: 0; 
			@include opacity(0);
		}
	}
}

//2.0 - Side Nav 
#main-nav {
	border-bottom: 1px solid $l-grey;
	float: left;
	margin-bottom: .5em;
	padding-bottom: .5em;
	width: 100%;
	> li {
		@include box-sizing();
		display: block;
		padding: .5em 1.5em;
		float: left;
		position: relative;
		width: 100%;
		&.has-child:after {
			content: "";
			border-bottom: 2px solid $grey;
			border-left: 2px solid $grey;
			height: .5em;
			position: absolute;
			right: 1.75em;
			top: 1.9em;
			width: .5em;
			@include transform(rotate(45deg));
			.mini-nav & {  
				display: none;
			}
			@include bp(tablet) {  
				display: none;
			}
		}
		> a {
			color: $grey;
			font-weight: bold; 
			svg {
				float: left;
				margin-right: .5em;
				height: 3em;
				width: 3em;
				path, rect { fill: $grey !important; } 
			}
			> span {
				display: block;
				float: left;
				height: 3em;
				left: 5em;
				line-height: 3em;
				position: absolute;
				@include opacity(1);
				.animate & {
					@include transition(opacity);
				}
				.mini-nav & { 
					height: 0; 
					overflow: hidden;
					@include opacity(0);
				}
				@include bp(tablet) {  
					height: 0; 
					overflow: hidden;
					@include opacity(0);
				}
			}
		}
		ul {
			display: none;
			float: left;
			width: 100%;
			li {
				list-style: none;
				&:last-child { margin-bottom: .5em; }
				&:first-child { margin-bottom: 0; }
				a {
					color: $grey;
					display: block;
					font-weight: bold; 
					padding: .25em 0 .25em 2.1em;
				}
			}
			.mini-nav & {  
				background: $black;
				box-shadow: .5em .5em .5em rgba(0,0,0, .15);
				left: 75%;
				padding: 0;
				position: absolute;
				top: 7px;
				width: 15em;
				z-index: 99;
				&:before {
					border: solid transparent;
					border-color: rgba(255, 255, 255, 0);
					border-right-color: darken($black, 10%);
					border-width: .85em;
					content: " ";
					height: 0;
					margin-top: -.85em;
					position: absolute;
					pointer-events: none;
					right: 100%;
					top: 1.75em;
					width: 0;
				}
				@include bp(tablet) {  
					background: $black;
					box-shadow: .5em .5em .5em rgba(0,0,0, .15);
					left: 75%;
					padding: .5em 0;
					position: absolute;
					top: 7px;
					width: 15em;
					z-index: 99;
					&:before {
						border: solid transparent;
						border-color: rgba(255, 255, 255, 0);
						border-right-color: $black;
						border-width: .85em;
						content: " ";
						height: 0;
						margin-top: -.85em;
						position: absolute;
						pointer-events: none;
						right: 100%;
						top: 1.75em;
						width: 0;
					}
				}
				li {
					a {
						color: $white;
						padding: .5em 1em;
					}
				}
			}
		}
		&.active-nav,
		&:hover {
			&.has-child:after { 
				border-color: $black;
				@include transform(rotate(-45deg));
			}
			a { 
				color: $black;
				svg { 
					path, rect { fill: $black !important; } 
				}
			}
			ul {
				display: block;
				.sub-nav-header { 
					display: none;
					.mini-nav & {
						background: darken($black, 10%);
						display: block;
						padding: .5em 0;
					}
				}
				.mini-nav & { display: none; }
				@include bp(tablet) { display: none; }
			}
		}
		@include bp(tablet) {  
			&.has-child:after { 
				border-color: $black;
				@include transform(rotate(-45deg));
			}
			a { 
				color: $black;
				svg { 
					path { fill: $black; } 
				}
			}
			ul {
				display: block;
				.mini-nav & { display: none; }
				@include bp(tablet) { display: none; }
			}
		}
		&:hover {
			ul {
				display: block !important;
			}
		}
	}
}


.feedback {
	@include box-sizing();
	color: $grey;
	display: block;
	float: left;
	font-weight: bold;
	margin-bottom: 2em;
	padding: .5em 1.5em;
	position: relative;
	width: 100%;
	svg {
		float: left;
		margin-right: .5em;
		height: 3em;
		width: 3em;
		path { stroke: $grey; } 
	}
	> span {
		display: block;
		float: left;
		height: 3em;
		left: 5em;
		line-height: 3em;
		position: absolute;
		.mini-nav & { display: none; }
		@include bp(tablet) { display: none; }
	}
	&:hover {
		color: $black;
		svg {
			path { stroke: $black; } 
			line { stroke: $black; }
		}
	}
}


