/*
What's Fresh - Frontend - Data Tables

TABLE OF CONTENTS:
1.0 - General
*/


//1.0 - General

.dataTables_wrapper {
	.dataTables_length {
		select {
			background-color: $white !important;
			background-position: 125% center;
			padding: 0 2em 0 .5em;
		}
	}
	.dataTables_filter {
		input{
			background: $white !important;
		}
	}
	
	table.dataTable tbody th, 
	table.dataTable tbody td {
		padding: 2px 5px;
		a {
			font-weight: bold;
		}
	}
}