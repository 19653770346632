/*
What's Fresh - Frontend - Styles/Login

TABLE OF CONTENTS:
1.0 - General
*/


//1.0 - General

body.login {
	background: $white;
	#main {
		float: none;
		margin: auto;
		max-width: em(550px);
		width: 100%;
		@include bp(allPh) {  
			max-width: 80%;
		}
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			width: 100%;
		}
	}
}

#login-branding {
	text-align: left;
	img { 
		display: inline-block;
		margin-top: 2em;
		height: 3.5em;
		width: auto;
		@include bp(allPh) {  
			height: 4em;
			margin-top: 4em;
		}
	}
	h1 {
		font-size: 2em;
		margin-top: .5em;
		text-align: left;
		@include bp(allPh) {  
			font-size: 2em;
			margin-top: 1em;
		}
	}
}

#auth-form-wrapper {
	margin-bottom: 4em;
}


#login-footer {
	bottom: 0;
	left: 0;
	margin: 0 calc((100% - 34.375em) / 2);
	max-width: 34.375em;
	position: fixed;
	width: 100%;
	@include bp(allPh) {
		margin: 0 10%;  
		max-width: 80%;
	}
	div {
		background-image: url('../img/login-footer.jpg');
		background-position: center top;
		color: transparent;
		padding: 1em 0;
	}
}