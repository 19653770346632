/*
What's Fresh - Frontend - Styles/Footer

TABLE OF CONTENTS:
1.0 - Pagename
*/


//1.0 - Pagename
#pagename {
	.viewfoot {
		svg {
			height: 1.25em;
			width: 2.5em;
			path { fill: $black;}
		}
	}
}