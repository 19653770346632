/*
What's Fresh - Frontend - Styles/Elements

TABLE OF CONTENTS:
1.0 - Nav List
2.0 - Modal Window
*/


//1.0 - Nav List
.nav-list {
	list-style: none;
	li {
		background: $white;
		margin-bottom: .5em;
		a {
			display: block;
			font-weight: bold;
			padding: .5em 1em;
			@include transition(background);
			&:hover {
				background: $teal;
				color: $white;
			}
		}
	}
}


//2.0 - Modal Window
.fade {
	-webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  &.fade { opacity: 0; }
  &.show { opacity: 0.5; }
}


.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: 10vh auto;
  max-width: 850px;
  pointer-events: none;
  position: relative;
  width: 80%;
}

.modal-content {
	background-color: $l-grey;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  pointer-events: auto;
  position: relative;
  outline: 0;
  width: 100%;
	.modal-header {
		background: $l-grey;
		padding: 1em 2em;
		h5 {
			float: left;
			font-size: 1.75em;
			margin-bottom: 0;
		}
		button {
			padding: 0;
	    float: right;
	    height: .75em;
	    font-size: 3em;
	    line-height: .75em;
	    box-shadow: none;
	    border: none;
	    background: none;
	    width: .75em;
		}
	}
  .fields {
	  margin: 0;
  }
  .other-actions {
	  list-style: none;
	  li {
		  float: left;
		  margin-right: 1em;
		  a {
			  padding: .625em 1.25em;
		  }
	  }
  }
}

.modal-body {
	background: $white;
	padding: 2em;
	.fields {
		padding: 0 0 1em;
	}
	p:last-child {
		margin-bottom: 0;
	}
	table.choices {
		tr  {
			border-top: 1px solid $l-grey;
			cursor: pointer;
		}
		tr td, tr th {
			padding: .35em .75em;
		}
		thead tr {
			border: none 0px;
			cursor: auto;
			padding: .25em .5em;
			text-align: left;
		}
		tr.selected {
			background: $l-grey;
		}
	}
	ul {
		list-style: none;
		li {
			border-top: 1px solid $l-grey;
			&:first-child { border: 0;}
			a {
				display: block;
				padding: .25em .5em;
			}
			&.selected a { background: $l-grey; }
		}
	}
	ul.pagination {
		list-style: none;
		li {
			border: none;
			float: left;
			span, a {
				display: block;
				border: 1px solid $l-grey;
				cursor: pointer;
				margin: 0 .5em;
				padding: .25em .5em;
			}
			&.active span, &:hover a {
				background: $l-grey;
			}
		}
	}
}

.modal-footer {
	background: $white;
	padding: 0 2em 2em;
}