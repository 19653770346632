/*
What's Fresh - Frontend - Styles/Base

TABLE OF CONTENTS:
1.0 - Typography
2.0 - Links
	2.1 - Buttons
3.0 - Elements
4.0 - Accessibility
5.0 - Alignments
6.0 - Clearings
7.0 - Media
*/


//1.0 - Typography
body,
button,
input,
select,
textarea,
#page {
	color: $font-color-default;
	font-family: $body;
	font-size: 16px;
	@include font-smoothing;
	line-height: 1.5;	
}	
h1, h2, h3, h4, h5, h6 {
	font-family: $headline;
	clear: both;
	line-height: 1.2em;
	margin: 0 0 0.5em 0;
}
h1 { font-size: em(42px); }
h2 { font-size: em(36px); }
h3 { font-size: em(28px); }
h4 { font-size: em(24px); }
h5 { font-size: em(21px); }
h6 { font-size: em(18px); }
p {
	margin-bottom: 1.5em;
}
b, strong {
	font-weight: bold;
}
dfn, cite, em, i {
	font-style: italic;
}
blockquote {
	margin: 0 1.5em;
}
address {
	margin: 0 0 1.5em;
}
pre {
	background: #eee;
	font-family: $body;
	font-size: 15px;
	font-size: 1.5rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}
code, kbd, tt, var {
	font: 15px $body;
}
abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}
mark, ins {
	background: #fff9c0;
	text-decoration: none;
}
sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	bottom: 1ex;
}
sub {
	top: .5ex;
}
small {
	font-size: 75%;
}
big {
	font-size: 125%;
}


//2.0 Links
a {
	color: $font-color-accent;
	text-decoration: none;
	&.phone-link {
		color: $black;
		font-weight: normal !important;
		pointer-events: none;
		@include bp(allPh) {  
			color: $font-color-accent;
			font-weight: $bold !important;
			pointer-events: auto;
		}
	}
}


//2.1 Buttons
.viewaction a,
a.button,
span.button,
button.btn {
	@include fresh-gradient();
	@include border-radius(3px);
	color: $font-color-light;
	display: inline-block;
	font-weight: 500;
	line-height: 1.5em;
	padding: em(10px) em(20px);
	@include transition(background);
	&:hover {
		background: darken($primary-1, 15%);
	}
}


//3.0 Elements
hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}
ul, ol {
	margin: 0;
}
ul {
	list-style: disc;
}
ol {
	list-style: decimal;
}
li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}
dt {
	font-weight: bold;
}
dd {
	margin: 0 1.5em 1.5em;
}
img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}
figure {
	margin: 0;
}
table {
	margin: 0 0 1.5em;
	width: 100%;
}
th {
	font-weight: bold;
}


//4.0 Accessibility
/* Text meant only for screen readers */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
}
.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}

//5.0 Alignments
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}
.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto;
}

//6.0 Clearings
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: '';
	display: table;
}
.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}


//7.0 Media
.page-content img.wp-smiley,
.entry-content img.wp-smiley,
.comment-content img.wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}
.wp-caption {
	border: 1px solid #ccc;
	margin-bottom: 1.5em;
	max-width: 100%;
}
.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 1.2% auto 0;
	max-width: 98%;
}
.wp-caption-text {
	text-align: center;
}
.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}
.site-main .gallery {
	margin-bottom: 1.5em;
}
.gallery-caption {
}
.site-main .gallery a img {
	border: none;
	height: auto;
	max-width: 90%;
}
.site-main .gallery dd,
.site-main .gallery figcaption {
	margin: 0;
}
.site-main .gallery-columns-4 .gallery-item {
}
.site-main .gallery-columns-4 .gallery-item img {
}
/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
	max-width: 100%;
}
