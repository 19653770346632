/*
What's Fresh - Frontend - Styles/Header

TABLE OF CONTENTS:
1.0 - Layout
*/


//1.0 - Layout

#masthead {
	background: $l-grey;
	border-bottom: 1px solid $white;
	padding: 0 !important;
	position: fixed;
	right: 0;
	width: calc(100% - 18em);
	z-index: 9;
	.animate & {
		@include transition(width);
	}
	.mini-nav & { width: calc(100% - 6em); }
	@include bp(tablet) { width: calc(100% - 6em); }
	#masthead-container {
		max-width: none;
		padding: 0 !important;
		width: 100%;
		#header-logo {
			background: $white;
			display: block;
			height: 4em;
			padding: .5em;
		}
		nav {
			@include box-sizing();
			float: left;
			padding: .9em 1em .9em;
			position: relative;
			width: 100%;
			#select-company {
				bottom: -1px;
				color: $violet;
				float: left;
				border-bottom: 1px solid $violet;
				position: absolute;
				select {
					background: transparent !important;
					cursor: pointer;
					font-size: 1em !important; 
					font-weight: bold !important;
					height: 4em;
					padding-right: 2em;
				}
				&:after {
					border-bottom: 1px solid $violet;
					border-right: 1px solid $violet;
					content: "";
					height: .5em;
					position: absolute;
					right: .5em;
					top: 1.75em;
					width: .5em;
					@include transform(rotate(45deg));
				}
			}
			.logout-button {
				float: right;
			}
		}
	}
}