/*
What's Fresh - Frontend - Styles/Layout

TABLE OF CONTENTS:
1.0 - Layout
*/

//1.0 - Layout
body {
	background: $l-grey;
}

#content-wrapper {
	padding: 0 !important;
	#content-container  {
		max-width: none;
		width: 100%;
	}
}

#side-nav {
	background: $white;
	bottom: 0;
	float: left;
	height: 100%;
	min-height: 100vh;
	overflow-x: visible;
	position: fixed;
	top: 0;
	width: 18em;
	z-index: 999;
	.animate & {
		@include transition(width);
	}
	.mini-nav & { width: 6em; }
	@include bp(tablet) { width: 6em; }
}

#main {
	float: right;
	position: relative;
	width: calc(100% - 18em);
	.animate & {
		@include transition(width);
	}
	.mini-nav & { width: calc(100% - 6em); }
	@include bp(tablet) { width: calc(100% - 6em); }
}

#main-content {
	@include box-sizing();
	float: left;
	padding: 7em 3em 3em;
	width: 100%;
}

#pagename {
	@include box-sizing();
	float: left;
	margin: 0 3em;
	width: calc(100% - 6em);
}