/*
What's Fresh - Frontend - Styles/comapny

Page: Home

TABLE OF CONTENTS:
1.0 - Dashboard Table 
*/


//1.0 - Dashboard Table 

#dashboard-table {
	text-align: center;
}
