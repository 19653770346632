/*
What's Fresh - Frontend - Styles/Forms

TABLE OF CONTENTS:
1.0 - General Forms Elements
*/


//1.0 - General Forms Elements
body {
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="tel"],
	input[type="text"],
	input[type="url"],
	input[type="search"],
	select,
	textarea {
		background-color: $l-grey !important; 
		border: 0 none;
		border-radius: 0px !important;
		@include box-sizing();
		background: transparent;
		color: $black;
		font-size: 100%; /* Corrects font size not being inherited in all browsers */
		margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
		vertical-align: baseline; /* Improves appearance and consistency in all browsers */
		*vertical-align: middle; /* Improves appearance and consistency in IE6/IE7 */
		-webkit-appearance: none;
		height: 3em;
		line-height: 3em;
		padding: 0 .5em ;
		outline: none;
	}
	textarea { 
		height: 6.5em;
		padding: 1em;
		line-height: 1.2em;
	}
	
	select {
	   -webkit-appearance: none;
	   -moz-appearance: none;
	   appearance: none;
	   background-image: url('../img/icons-select.svg');
		 background-position: right center;
		 background-repeat: no-repeat;
	}
	input[type="submit"] {
		border: none;
		background: $blue;
		@include border-radius(3px);
		color: $font-color-light;
		cursor: pointer;
		display: inline-block;
		float: left;
		font-weight: 500;
		line-height: 1.5em;
		margin-right: 1.5em;
		padding: em(10px) em(20px);
		@include transition(background);
		width: 8em !important;
		&:hover {
			background: darken($blue, 5%);
		}
	}
}


.fields {
	background: $white;
	@include box-sizing();
	float: left;
	margin: 2em 0 0;
	padding: 1.75em 2em 2em;
	width: 100%;
	> div,
	.fields {
		float: left;
		margin-bottom: 1em;
		width: 100%;
		&.half {
			> div {
				float: left;
				margin-left: 1.5em;
				width: calc(50% - .75em);
				@include bp(allPh) {  
					margin-left: 0;
					width: 100%;
				}
				&:first-child {
					margin-left: 0;
				}
			}
		}
		&.third {
			> div {
				float: left;
				margin-left: 1.5em;
				width: calc(33.33% - 1em);
				@include bp(allPh) {  
					margin-left: 0;
					width: 100%;
				}
				&:first-child {
					margin-left: 0;
				}
			}
		}
		&.fourth {
			
		}
		.hidden-fields {
			display: none;
		}
		label {
			display: block;
			font-weight: 500;
			margin-bottom: .25em;
			width: 100%;
			svg {
				cursor: pointer;
			}
		}
		input,
		select,
		textarea {
			display: block;
			width: 100%;
		}
	}
}


.fields .field {
	.unit-quantity {
		width: 100% !important;
		> div {
			float: left;
			margin-left: 1.5em;
			width: calc(50% - .75em);
			@include bp(allPh) {  
				margin-left: 0;
				width: 100%;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.fields .field.vendors-brands {
	> div {
		float: left;
		margin-left: 1.5em;
		width: calc(50% - .75em);
		@include bp(allPh) {  
			margin-left: 0;
			width: 100%;
		}
		&:first-child {
			margin-left: 0;
		}
	}
}

.fields .field.measurements { 
	> div { float: left; }
	.quantity, .price {
		width: 20% !important;
	}
	.separator { 
		text-align: center;
		height: 5em;
		line-height: 6em;
		width: 7%; 
	}
	.unit-quantity {
		width: 46% !important;
		> div {
			margin: 0; 
			width: 43%;
		}
		.separator { 
			width: 14%; 
		}
	}
	.total-cost {
		margin: 1.5em 0 1em;
		width: 100%;
		> div {
			float: left;
			margin-left: 1.5em;
			width: calc(50% - .75em);
			@include bp(allPh) {  
				margin-left: 0;
				width: 100%;
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
}

.ui-datepicker {
	background: $white;
	border: 1px solid $l-grey;
	@include box-sizing();
	padding: .5em;
	width: 15em;
	text-align: center;
	.ui-datepicker-prev {
		float: left;
	}
	.ui-datepicker-next {
		float: right;
	}
	.ui-datepicker-title {
		float: left;
		width: 100%;
	}
}