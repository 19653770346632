/*
What's Fresh - Frontend - Styles/comapny

TABLE OF CONTENTS:
1.0 - General
*/


//1.0 - General
.users {
	margin-top: 2em;
}

.user-list {
	background: $white;
	float: left;;
	list-style: none;
	margin-bottom: 3em;
	> li {
		border-bottom: 1px solid $l-grey;
		float: left;
		padding: .75em 0;
		width: 100%;
		&:last-child {
			border: none;
		}
		&:first-child { font-weight: 500; }
		> ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				float: left;
				input,
				a {
					display: inline-block;
					width: auto;
				}
			}
			li:first-child { width: calc(100% - 8em); }
			li:nth-child(2) { text-align: center; width: 5em; }
			li:nth-child(3) { text-align: center; width: 3em; }
		}
	}
}

.worker-list {
	background: $white;
	float: left;;
	list-style: none;
	margin-top: 2em;
	padding: 1em 2em 2em;
	> li {
		border-bottom: 1px solid $l-grey;
		float: left;
		padding: .75em 0;
		width: 100%;
		&:last-child {
			border-bottom: none;
		}
		a.remove {
			float: right;
			margin-right: 1em;
		}
	}
}